<template>
    <div>
        <div v-if="!preDelete">
            <v-btn color="error" x-small dark @click="preDelete = true">
                Отключить
            </v-btn>
        </div>
        <div class="mt-2 mb-2 d-flex justify-center flex-column" v-else>
            <b>Действительно отключить курьера {{ displayName(courier) }}?</b>
            <div v-if="courier.ordersActual.length">
                <div>Заказы, которые доставляет курьер сделать как:</div>
                <div class="d-flex justify-center mb-2">
                    <v-radio-group row v-model="ordersStatus" mandatory hide-details>
                        <v-radio label="Новые" :color="orderStatusColorHtml1(OrderStatus.new)" :value="OrderStatus.new"></v-radio>
                        <v-radio label="Доставлены" :color="orderStatusColorHtml1(OrderStatus.complete)" :value="OrderStatus.complete"></v-radio>
                    </v-radio-group>
                </div>
            </div>
            <v-divider class="mx-4"></v-divider>
            <div class="ml-2">
                <v-btn text color="primary" @click="disableCourier()">Отключить</v-btn>
                <v-btn text @click="cancel()">Отмена</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '../../../config'
    import Orders from '../../../mixins/Orders.js'
    import Status from '../../../mixins/Status.js'
    import Notify from '../../../mixins/Notify.js'

    export default {
        data() {
            return {
                config,
                currentItem: {},
                ordersStatus: null,
                preDelete: false,
            }
        },
        props: {
            courier: {
                type: Object,
                default: null,
            },
        },
        mixins: [Orders, Status, Notify],
        methods: {
            displayName(courier) {
                const firstName = courier.user?.firstName ?? ''
                const lastName = courier.user?.lastName ?? ''
                return firstName + ' ' + lastName
            },
            disableCourier() {
                this.$store
                    .dispatch('courier_disconnect', {courierId: this.courier.id, newOrderStatus: this.ordersStatus === this.OrderStatus.new})
                    .then(res => {
                        if (res.data.success) {
                            console.log('courier_disconnect ok')
                            this.notifyToast('Курьер отключен успешно', { color: 'success', x: 'right', y: 'top', timeout: 5000 })
                        } else {
                            console.log('courier_disconnect fail')
                            this.notifyToast('Не удалось отключить курьера', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                        }
                        this.$emit('update')
                    })
                    .catch(err => {
                        console.log('courier_disconnect error', err.name, err.message)
                        this.notifyToast('Не удалось отключить курьера', { color: 'error', x: 'right', y: 'top', timeout: 15000 })
                    })
            },
            cancel() {
                this.preDelete = false
            },
        },
    }
</script>
