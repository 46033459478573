<template>
    <div class="text-center card">
        <v-card>
            <v-window v-model="step">
                <v-window-item :value="1">
                    <v-card-title class="pt-0 pb-2 mt-2 d-flex">
                        <v-select style="width: 40%;" color="deep-purple pointer accent-4" class="mx-2 flex-grow-0" no-data-text="Нет данных" width="50" :items="filter_order_modes" name="order_mode" v-model="filter_order_mode" label="По типу" dense hide-details> </v-select>

                        <v-spacer></v-spacer>

                        <v-text-field style="width: 40%;" color="deep-purple accent-4" append-icon="mdi-magnify" :label="$t('common.search')" outlined dense rounded single-line hide-details v-model="searchOrder"> </v-text-field>
                        
                        <v-spacer></v-spacer>

                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                            <!-- :item-class="itemRowBackground" -->
                        <v-data-table
                            v-model="selectedOrders"
                            :headers="orderHeaders"
                            :items="filteredOrders"
                            :single-select="singleSelectOrder"
                            :search="searchOrder"
                            item-key="id"
                            show-select
                            style="max-height: 65vh; min-height: 65vh;"
                            class="elevation-0  overflow-y-auto"
                            no-data-text="Нет новых заказов"
                            no-results-text="Нет такого заказа, попробуйте изменить запрос"
                            checkbox-color="deep-purple accent-4"
                            hide-default-footer
                            :footer-props="{
                                itemsPerPageOptions: [-1],
                            }"
                            @click:row="onClickSelectOrder($event)"
                        >
                        <!-- FIXME: Восстановлен @click:row="onClickSelectOrder($event), это важная функция. Если некорректно отрабатывает выбор мультизаказа, то его починить" -->
                            <template v-slot:[`item.name`]="{ item }">
                                <div class="d-flex" :class="rowItemClass">
                                    <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-chip small v-bind="attrs" v-on="on" :color="Array.isArray(item.orders) ? orderStatusColorHtml1(item.status, true) : orderStatusColorHtml1(item.status)" text-color="white">
                                                <v-icon size="small" class="mr-1">{{ orderStatusIcon1(item.status) }}</v-icon>
                                                {{ Array.isArray(item.orders) ? `${item.name.split('/')[0]} +${(item.orders.length - 1)}` : item.name }}
                                            </v-chip>   
                                        </template>
                                        <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                            <div class="order-card" v-html="Array.isArray(item.orders) ? multiOrderFullText1(item.point, item) : orderFullText1(item.point, item)"></div>
                                        </v-card>
                                    </v-menu>
                                </div>
                            </template>

                            <template v-slot:[`item.createdAt`]="{ item }"> {{ getOrderMode(item, true) }} {{ timeToString(item.createdAt) }} </template>
                            <template v-slot:[`item.dateReady`]="{ item }"> {{ timeToString(item.dateReady) }} </template>
                            <template v-slot:[`item.dateDelivery`]="{ item }"> {{ timeToString(item.dateDelivery) }} </template>
                        </v-data-table>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                    <v-card-title class="pt-0 pb-2 d-flex">
                        <v-select class="mx-2 flex-grow-0" style="width: 40%;" color="deep-purple accent-4" no-data-text="Нет данных" :items="filter_statuses" name="order_mode" v-model="filter_status" label="По статусу" hide-details>
                            <template slot="selection" slot-scope="data">
                                <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                                >{{ data.item.text }}
                            </template>
                            <template slot="item" slot-scope="data">
                                <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                                >{{ data.item.text }}
                            </template>
                        </v-select>
                        <v-spacer></v-spacer>
                        <v-text-field style="width: 40%;" outlined dense rounded color="deep-purple accent-4" v-model="searchCourier" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <v-data-table
                            v-model="selectedCouriers"
                            :headers="courierHeaders"
                            :items="filteredCouriers"
                            :single-select="singleSelectCourier"
                            :search="searchCourier"
                            item-key="id"
                            style="max-height: 65vh; min-height: 65vh;"
                            class="elevation-0 pointer overflow-y-auto"
                            show-select
                            fixed-header
                            no-data-text="Нет подключенных курьеров"
                            no-results-text="Нет такого курьера, попробуйте изменить запрос"
                            checkbox-color="deep-purple accent-4"
                            hide-default-footer
                            :footer-props="{
                                itemsPerPageOptions: [-1],
                            }"
                            @click:row="onClickSelectCourier($event)"
                        >
                            <template v-slot:[`item.name`]="{ item }">
                                <v-avatar size="24" class="mr-2">
                                    <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + item.user?.telegramAccount?.chatId}`" />
                                </v-avatar>
                                {{ item.name }}
                            </template>

                            <template v-slot:[`item.orders`]="{ item }">
                                <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y v-for="order of item.ordersActual" :key="order.id">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on" class="item-order d-inline-block" :style="orderStatusStyle1(order)">{{ getOrderName(order) }}</span>
                                    </template>

                                    <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                        <div class="order-card" v-html="orderFullText1(order.point, order)"></div>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                    <div class="h-600 d-flex text-center justify-center align-center">
                        <v-card width="900px" class="elevation-0" outlined>
                            <div>
                                <h2 class="text-h4 font-weight-light mb-2 mt-10">
                                    {{ selectedOrders.length > 1 ? 'Заказы:' : 'Заказ:' }}
                                </h2>

                                <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y v-for="order of selectedOrders" :key="order.id">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on" class="item-order d-inline-block" :style="Array.isArray(order.orders) ? orderStatusStyle1(order, true) : orderStatusStyle1(order)">
                                            {{ Array.isArray(order.orders) ? `${order.name.split('/')[0]} +${(order.orders.length - 1)}` : getOrderName(order) }}
                                        </span>
                                    </template>

                                    <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                        <div class="order-card" v-html="Array.isArray(order.orders) ? multiOrderFullText1(order.point, order) : orderFullText1(order.point, order)"></div>
                                    </v-card>
                                </v-menu>

                                <h2 class="text-h4 font-weight-light mb-2 mt-5">
                                    Курьеру:
                                </h2>

                                <div v-for="courier of selectedCouriers" :key="courier.id" class="mb-10" outlined>
                                    <v-avatar size="24" class="mr-2">
                                        <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + courier.user.telegramAccount.chatId}`" />
                                    </v-avatar>
                                    {{ displayName(courier) }}
                                </div>

                                <div class="mt-5">
                                    <v-btn text @click="reset()">
                                        Отмена
                                    </v-btn>
                                    <v-btn @click="sendOrder()" text color="success">
                                        Отправить
                                    </v-btn>
                                </div>

                                <div class="mb-10">
                                    <span class="text-caption grey--text">Для переоформления заказа/курьера нажмите "Отмена"</span>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </v-window-item>
            </v-window>

            <v-card-actions v-if="step < 3">
                <v-btn :disabled="step === 1" text @click="step--" class="ml-3">
                    Назад
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn color="#031E4D" dark class="mr-3" depressed @click="nextStep()">
                    Далее
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import config from '../../config'
    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'
    import Notify from '../../mixins/Notify.js'

    export default {
        data() {
            return {
                singleSelectOrder: false,
                selectedOrders: [],
                singleSelectCourier: true,
                selectedCouriers: [],
                model: [],
                orderHeaders: [
                    { text: 'Название', value: 'name', sortable: false },
                    { text: 'Готов', value: 'createdAt' },
                    { text: 'Забрать', value: 'dateReady' },
                    { text: 'Доставить', value: 'dateDelivery' },
                ],
                courierHeaders: [
                    { text: 'Имя курьера', value: 'name' },
                    { text: 'Процесс заказов', value: 'orders' },
                ],
                filter_order_mode: 0,
                filter_order_modes: [
                    {
                        text: '< Все >',
                        value: 0,
                    },
                    {
                        text: this.getOrderModeString(true),
                        value: 1,
                    },
                    {
                        text: this.getOrderModeString(false),
                        value: 2,
                    },
                ],
                filter_status: 0,
                filter_statuses: [],
                // computedCourier: {
                //     tid: '',
                //     full_name: '',
                // },
                config,
                nameOrder: '',
                nameCourier: '',
                currentItem: {},
                searchOrder: '',
                searchCourier: '',
                step: 1,
                interval: null,
                userIsPoint: false,
            }
        },
        props: {
            couriers: {
                type: Array,
                default: null,
            },
            orders: {
                type: Array,
                default: null,
            },
            // multiOrders: {
            //     type: Array,
            //     default: null,                
            // }
        },
        mixins: [DateTime, Status, Orders, Notify],
        computed: {
            filteredOrders() {
                const allOrders = this.orders
                // const allOrders = [...this.orders, ...this.multiOrders]

                return allOrders.filter(order => {
                    order.name = Array.isArray(order.orders) ? order.orders.map(e => this.getOrderName(e)).join(' / ') : this.getOrderName(order)
                    
                    if (this.filter_order_mode === 1) {
                        return Array.isArray(order.orders) ? !order.orders[0].isNearest : !order.isNearest
                    } else if (this.filter_order_mode === 2) {
                        return Array.isArray(order.orders) ? order.orders[0].isNearest : order.isNearest
                    }

                    return true
                })
            },
            filteredCouriers() {
                return this.couriers.filter(courier => {
                    courier.name = this.displayName(courier)
                    
                    if (this.filter_status !== 0) {
                        return courier.status === this.filter_status
                    }
                    
                    return true
                })
            },
        },
        methods: {
            displayName(courier) {
                const firstName = courier.user?.firstName ?? ''
                const lastName = courier.user?.lastName ?? ''
                return firstName + ' ' + lastName
            },
            // itemRowBackground(item) {
            //     if (item.options.combined) {
            //         return 'combined-order'
            //     }
            // },
            onClickSelectOrder(order) {
                if (!this.selectedOrders.includes(order)) {
                    this.selectedOrders.push(order)
                } else {
                    this.selectedOrders = this.selectedOrders.filter(o => o.id != order.id)
                }
            },
            // FIXME: Скорей всего тоже некорректно работает как и onClickSelectOrder
            onClickSelectCourier(courier) {
                if (!this.selectedCouriers.includes(courier)) {
                    if (this.selectedCouriers.length >= 1) {
                        this.selectedCouriers.pop()
                        this.selectedCouriers.push(courier)
                    } else {
                        this.selectedCouriers.push(courier)
                    }
                } else {
                    this.selectedCouriers = this.selectedCouriers.filter(c => c.id != courier.id)
                }
            },
            onClickUpdate() {
                this.$emit('update')
            },
            // isCombinedOrder(item) {
            //     let options = item.options //JSON.parse(item.options)
            //     console.log("options", options)
            //     return options.combined
            // },
            rowItemClass(item) {
                // if (this.isCombinedOrder(item)) {
                //     return 'combined-order'
                // }
                return ''
            },
            nextStep() {
                switch (this.step) {
                    case 1:
                        if (this.selectedOrders.length > 0) {
                            this.step++
                        } else {
                            return null
                        }
                        break
                    case 2:
                        if (this.selectedCouriers.length > 0) {
                            this.step++
                        } else {
                            return null
                        }
                        break
                    default:
                        return null
                }
            },
            reset() {
                this.selectedOrders = []
                this.selectedCouriers = []
                this.step = 1
            },
            async sendOrder() {
                const res = await this.$store.dispatch('order_assign', {
                    courierId: this.selectedCouriers[0].id,
                    orderIds: this.selectedOrders.map(order => order.id),
                    // orderIds: this.selectedOrders.filter(e => !Array.isArray(e.orders)).map(order => order.id),
                    // multiOrderIds: this.selectedOrders.filter(e => Array.isArray(e.orders)).map(multiOrder => multiOrder.id)
                })
                let msg = ''
                console.log('res', res)
                if (res.data.success) {
                    if (this.selectedOrders.length > 1) {
                        msg = `Заказы (${this.selectedOrders.length}) назначены успешно`
                    } else {
                        msg = 'Заказ назначен успешно'
                    }
                    this.notifyToast(msg, { color: 'success', timeout: 5000 })
                } else {
                    msg = 'Не удалось назначить заказ'
                    if (this.selectedOrders.length > 1) {
                        msg += 'ы'
                    }
                    this.notifyToast(msg, { color: 'error', timeout: 15000 })
                }
                this.$emit('update')
                this.reset()
            },
        },
        mounted() {
            this.step = 1
            this.filter_statuses = [
                {
                    text: '< Все >',
                    value: 0,
                },
                {
                    text: 'Свободен',
                    value: this.CourierStatus.free,
                    icon: 'mdi-check-bold',
                },
                {
                    text: 'Обрабатывает',
                    value: this.CourierStatus.processing,
                    icon: 'mdi-timer-sand',
                },
                {
                    text: 'Доставляет',
                    value: this.CourierStatus.delivering,
                    icon: 'mdi-run-fast',
                },
            ]
        },
    }
</script>

<style>
    /* .combined-order {
        background-color: #fbe6ff
    }
    .combined-order:hover {
        background-color: #eed3f3 !important
    } */

    .scroll {
        overflow-y: auto;
    }
    .h-600 {
        height: 550px;
    }
    .pointer {
        cursor: pointer;
    }
</style>
