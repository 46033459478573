<template>
    <div v-if="this.$store.state.isLogistic" v-bind:class="{ close: this.$store.state.isServiceWindowClose }" id="drag" @click="onClick($event)">
        <vue-draggable-resizable class-name="block" :prevent-deactivation="true" :active="true" :draggable="false" :min-width="450"  :w="width" @resizing="resize" :handles="['mr']" >
            <v-system-bar height="25" color="#031E4D" window dark>
                <v-btn icon @click="closeWindow">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-icon>mdi-hammer-screwdriver</v-icon>
                <span>Новых услуг: {{ newOrdersCount }}</span>
                <v-icon class="ml-3">mdi-account-hard-hat</v-icon>
                <span>Сервисменов: {{ workersCount }}</span>
                <v-spacer></v-spacer>
            </v-system-bar>

            <v-tabs color="#031E4D" grow>
                <v-tabs-slider color="white"></v-tabs-slider>
                
                <v-tab>Отправить</v-tab>
                <v-tab>Отменить</v-tab>
                <!--<v-tab v-if="userIsService">Отключить</v-tab>-->

                <v-tab-item>
                    <v-card flat>
                        <set-service :workers="workers" :orders="newOrders" @update="dataUpdate"></set-service>
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card flat>
                        <cancel-service :workers="workers" :orders="notNewOrders" @update="dataUpdate"></cancel-service>
                    </v-card>
                </v-tab-item>

                <!--<v-tab-item v-if="userIsService">
                    <v-card flat>
                        <disable-worker :workers="workers" :orders="notNewOrders" @update="dataUpdate"></disable-worker>
                    </v-card>
                </v-tab-item>!-->
            </v-tabs>

        </vue-draggable-resizable>
    </div>
</template>

<script>
    import config from '../../config'
    import VueDraggableResizable from 'vue-draggable-resizable'
    import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
    import SetService from './SetService.vue'
    import CancelService from './CancelService.vue'
    //import DisableWorker from './DisableWorker.vue'

    export default {
        data() {
            return {
                width: this.$store.state.width,
                userIsOperator:false,
                userIsService:false,
            }
        },
        props: {
            workers: {
                type: Array,
                default: null,
            },
            orders: {
                type: Array,
                default: null,
            },
        },
        methods: {
            dataUpdate() {
                this.$emit('update')
            },
            onClick(event) {
                // console.log('CLICK', event.target)
            },
            closeWindow() {
                this.$store.state.isServiceWindowClose = true
            },
            resize(left, top, width, heigh) {
                console.log('resize:', width)
                this.$store.commit('window_width', width)
                console.log('resize store: ', this.$store.state.width)
            },
        },
        computed: {
            newOrdersCount() {
                if(this.userIsOperator){
                    return this.orders.filter(o => o.status == 0||o.status == 7).length
                }else{
                    return this.orders.filter(o => o.status == 7).length
                }
            },
            newOrders() {
                if(this.userIsOperator){
                    return this.orders.filter(o => o.status == 0||o.status == 7)
                }else{
                    return this.orders.filter(o => o.status == 7)
                }
                
            },
            notNewOrders() {
                return this.orders.filter(o => o.status == config.order.status.passed || o.status == config.order.status.way_point || o.status == config.order.status.way_guest)
            },
            workersCount() {
                return this.workers.length
            },
        },
        mounted() {
            this.userIsOperator = this.$store.state.user.role == config.user.role.operator
            this.userIsService = this.$store.state.user.role == config.user.role.service
        },
        components: {
            VueDraggableResizable,
            SetService,
            CancelService,
            //DisableWorker,
        },
    }
</script>

<style lang="stylus">
    #drag
        z-index 5
    .size
        background-color white
        height 100%
        overflow hidden
    .close
        display: none
    .block
        border none
    .handle-mr
         border-radius 10px
         margin-top 20vh
         padding 15px
         height 30vh
         background #031E4D !important 
</style>
