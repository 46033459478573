<template>
    <div>
        <v-card>
            <v-card-title class="pt-0 pb-2 d-flex">
                <v-select style="width: 40%;" color="deep-purple accent-4" class="mx-2 flex-grow-0" no-data-text="Нет данных" :items="filter_order_modes" name="order_mode" v-model="filter_order_mode" label="По типу" hide-details> </v-select>

                <v-spacer></v-spacer>

                <v-text-field style="width: 40%;" color="deep-purple accent-4" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" outlined dense rounded single-line hide-details></v-text-field>

                <v-spacer></v-spacer>

                <v-btn icon @click="dataUpdate">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    style="max-height: 70vh; min-height: 70vh; cursor: pointer;"
                    :headers="headers"
                    :items="filteredOrders"
                    :search="search"
                    :single-expand="singleExpand"
                    show-expand
                    item-key="name"
                    fixed-header
                    class="elevation-0 overflow-y-auto"
                    no-data-text="Нет активных заказов"
                    no-results-text="Нет такого заказа, попробуйте изменить запрос"
                    checkbox-color="deep-purple accent-4"
                    hide-default-footer
                    :footer-props="{
                        itemsPerPageOptions: [-1],
                    }"
                    @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                >
                    <template v-slot:[`item.name`]="{ item }">
                        <div class="d-flex">
                            <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip small v-bind="attrs" v-on="on" :color="orderStatusColorHtml(item.status)" text-color="white">
                                        <v-icon size="small" class="mr-1">{{ orderStatusIcon(item.status) }}</v-icon>
                                        {{ item.name }}
                                    </v-chip>
                                </template>
                                <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                    <div class="order-card" v-html="orderContent(item,true)"></div>
                                </v-card>
                            </v-menu>
                        </div>
                    </template>

                    <template v-slot:[`item.date`]="{ item }"> {{ item.mode.split(' ')[0] }} {{ timeToString(item.date) }} </template>

                    <template v-slot:[`item.date_ready`]="{ item }">
                        {{ timeToString(item.date_ready) }}
                    </template>

                    <template v-slot:[`item.date_delivery`]="{ item }">
                        {{ timeToString(item.date_delivery) }}
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <div v-if="item.status == config.order.status.way_point || item.status == config.order.status.way_guest || item.status == config.order.status.passed || item.status == config.order.status.deleted">
                                <order-row-action :order="item" :workers="workers" @update="dataUpdate"></order-row-action>
                            </div>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-divider></v-divider>
        </v-card>
    </div>
</template>

<script>
    import config from '../../config'
    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'
    import OrderRowAction from './row_action/OrderRowAction.vue'

    export default {
        data() {
            return {
                e1: 1,
                config,
                search: '',
                singleExpand: false,
                headers: [
                    {
                        text: 'Название',
                        value: 'name',
                        sortable: false,
                    },
                    {
                        text: 'Готов',
                        value: 'date',
                        sortable: true,
                    },
                    {
                        text: 'Выполнить',
                        value: 'date_delivery',
                        sortable: true,
                    },
                    {
                        text: 'Действие',
                        value: 'data-table-expand',
                        sortable: false,
                    },
                ],
                filter_order_mode: 0,
                filter_order_modes: [
                    {
                        text: '< Все >',
                        value: 0,
                    },
                    {
                        text: '⏰ Предзаказ',
                        value: 1,
                    },
                    {
                        text: '🔥 Срочный заказ',
                        value: 2,
                    },
                ],
                currentItem: {},
            }
        },
        props: {
            workers: {
                type: Array,
                default: null,
            },
            orders: {
                type: Array,
                default: null,
            },
        },
        components: {
            OrderRowAction,
        },
        mixins: [DateTime, Orders, Status],
        computed: {
            filteredOrders() {
                return this.orders.filter(o => (this.filter_order_mode ? o.mode == this.filter_order_modes[this.filter_order_mode].text : true))
            },
        },
        methods: {
            dataUpdate() {
                this.$emit('update')
            },
        },
    }
</script>
