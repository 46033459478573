<template>
    <div v-if="this.$store.state.isLogistic" id="drag">
        <vue-draggable-resizable class-name="block" :prevent-deactivation="true" :active="true" :draggable="false" :min-width="450" :w="width" @resizestop="resize" :handles="['mr']" >
            <v-system-bar height="25" color="#031E4D" window dark>
                <v-btn icon @click="closeWindow">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-spacer></v-spacer>

                <v-icon>mdi-inbox-multiple</v-icon>
                <span>Новых заказов: {{ newOrdersCount }}</span>
                <v-icon class="ml-3">mdi-car-multiple</v-icon>
                <span>Курьеров: {{ freeCouriersCount }}</span>

                <v-spacer></v-spacer>
            </v-system-bar>

            <v-tabs color="#031E4D" grow>
                <v-tabs-slider color="white"></v-tabs-slider>
                
                <v-tab>Отправить</v-tab>
                <v-tab>Отменить</v-tab>
                <v-tab>Отключить</v-tab>

                <v-tab-item>
                    <v-card flat>
                        <assign-order :couriers="couriers" :orders="newOrders" @update="dataUpdate"></assign-order>
                        <!-- <assign-order :couriers="couriers" :orders="newOrders" :multiOrders="newMultiOrder" @update="dataUpdate"></assign-order> -->
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card flat>
                        <cancel-order :couriers="couriers" :orders="notNewOrders" @update="dataUpdate"></cancel-order>
                        <!-- <cancel-order :couriers="couriers" :orders="notNewOrders" :multiOrders="notNewMultiOrders" @update="dataUpdate"></cancel-order> -->
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card flat>
                        <disable-courier :couriers="couriers" :orders="notNewOrders" @update="dataUpdate"></disable-courier>
                        <!-- <disable-courier :couriers="couriers" :orders="notNewOrders" :multiOrders="notNewMultiOrders" @update="dataUpdate"></disable-courier> -->
                    </v-card>
                </v-tab-item>
            </v-tabs>

        </vue-draggable-resizable>
    </div>
</template>

<script>
    import config from '../../config'
    import VueDraggableResizable from 'vue-draggable-resizable'
    import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
    import AssignOrder from './AssignOrder.vue'
    import CancelOrder from './CancelOrder.vue'
    import DisableCourier from './DisableCourier.vue'

    import Orders from '../../mixins/Orders.js'

    export default {
        mixins: [Orders],
        data() {
            return {
                width: this.$store.state.width,
            }
        },
        props: {
            couriers: {
                type: Array,
                default: null,
            },
            orders: {
                type: Array,
                default: null,
            },
            // multiOrders: {
            //     type: Array,
            //     default: null,
            // },
        },
        created() {
            this.$emit('open')
        },
        destroyed() {
            this.$emit('close')
        },
        methods: {
            dataUpdate() {
                this.$emit('update')
            },
            onClick(event) {
                // console.log('CLICK', event.target)
            },
            closeWindow() {
                this.$store.state.isOrderWindowClose = true
                this.$emit('close')
            },
            resize(left, top, width, heigh) {
                console.log('resize:', width)
                this.$store.commit('window_width', width)
                console.log('resize store: ', this.$store.state.width)
                this.$emit('resize', {left, top, width, heigh})
            },
        },
        computed: {
            newOrdersCount() {
                return this.orders.length
                // return this.orders.length + this.multiOrders.length
                // return this.orders.filter(o => o.status == this.OrderStatus.new).length
            },
            newOrders() {
                return this.orders
                // return this.orders.filter(o => o.status == this.OrderStatus.new)
            },
            notNewOrders() {
                const orders = []

                this.couriers.forEach(courier => {
                    orders.push(...courier.ordersActual)
                })
                return orders
                // return this.orders.filter(o => o.status == this.OrderStatus.passed || o.status == this.OrderStatus.onWayPoint || o.status == this.OrderStatus.onWayCustomer)
            },
            // newMultiOrder() {
            //     return this.multiOrders
            // },
            // notNewMultiOrders() {
            //     const multiOrders = []

            //     this.couriers.forEach(courier => {
            //         multiOrders.push(...courier.multiOrdersActual)
            //     })

            //     return multiOrders
            // },
            freeCouriersCount() {
                return this.couriers.length
            },
        },
        components: {
            VueDraggableResizable,
            AssignOrder,
            CancelOrder,
            DisableCourier,
        },
    }
</script>

<style lang="stylus">
    #drag
        z-index 5
        .v-card__actions
            display: flex
            padding: 8px
            bottom: 0
            position: absolute
            width: 100%
    .size
        background-color white
        height 100%
        overflow hidden
    .close
        display: none
    .block
        border none
    .handle-mr
         border-radius 10px
         margin-top 20vh
         padding 15px
         height 30vh
         background #031E4D !important 
</style>
