<template>
    <div class="text-center">
        <v-card>
            <v-card-title class="pt-0 pb-2 d-flex">
                <v-select style="width: 40%;" class="mx-2 flex-grow-0" color="deep-purple accent-4" no-data-text="Нет данных" :items="filter_couriers" name="order_mode" v-model="filter_courier" label="По статусу" hide-details>
                    <template slot="selection" slot-scope="data">
                        <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                        >{{ data.item.text }}
                    </template>
                    <template slot="item" slot-scope="data">
                        <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon
                        >{{ data.item.text }}
                    </template>
                </v-select>
                <v-spacer></v-spacer>
                <v-text-field style="width: 40%;" color="deep-purple accent-4" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details outlined dense rounded></v-text-field>
                <v-spacer></v-spacer>
                <v-btn icon @click="dataUpdate">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    style="max-height: 70vh; min-height: 70vh; cursor: pointer;"
                    :headers="courierHeaders"
                    :items="filteredCouriers"
                    :search="search"
                    item-key="tid"
                    fixed-header
                    show-expand
                    class="elevation-0 overflow-y-auto"
                    no-data-text="Нет подключенных курьеров"
                    no-results-text="Нет такого курьера, попробуйте изменить запрос"
                    hide-default-footer
                    :footer-props="{
                        itemsPerPageOptions: [-1],
                    }"
                    @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                >
                    <template v-slot:[`item.name`]="{ item }">
                        <v-avatar size="24" class="mr-2">
                            <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + item.user?.telegramAccount?.chatId}`" />
                        </v-avatar>
                        {{ item.name }}
                    </template>

                    <template v-slot:[`item.orders`]="{ item }">
                        <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y v-for="order of item.ordersActual" :key="order.id">
                        <!-- <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y v-for="order of [...item.ordersActual, ...item.multiOrdersActual]" :key="order.id"> -->
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="item-order d-inline-block" :style="Array.isArray(order.orders) ? orderStatusStyle1(order, true) : orderStatusStyle1(order)">
                                    {{ Array.isArray(order.orders) ? `${order.name.split('/')[0]} +${(order.orders.length - 1)}` : getOrderName(order) }}
                                </span>
                            </template>

                            <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                <div class="order-card" v-html="orderFullText1(order.point, order)"></div>
                                <!-- <div class="order-card" v-html="Array.isArray(order.orders) ? multiOrderFullText1(order) : orderFullText1(order)"></div> -->
                            </v-card>
                        </v-menu>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <courier-row-action :courier="item" @update="dataUpdate"></courier-row-action>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import config from '../../config'
    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'
    import CourierRowAction from './row_action/CourierRowAction.vue'

    export default {
        data() {
            return {
                courierHeaders: [
                    { text: 'Имя курьера', value: 'name', sortable: false },
                    { text: 'Процесс заказов', value: 'orders', sortable: false },
                    { text: 'Действия', value: 'data-table-expand', sortable: false },
                ],
                filter_courier: 0,
                filter_couriers: [],
                config,
                search: '',
                orders_status: 0,
                currentItem: {},
            }
        },
        mixins: [DateTime, Status, Orders],
        components: {
            CourierRowAction,
        },
        props: {
            couriers: {
                type: Array,
                default: null,
            },
            orders: {
                type: Array,
                default: null,
            },
            // multiOrders: {
            //     type: Array,
            //     default: null,
            // },
        },
        computed: {
            filteredCouriers() {
                return this.couriers.filter(courier => {
                    courier.name = this.displayName(courier)
                    
                    if (this.filter_courier !== 0) {
                        return courier.status === this.filter_courier
                    }

                    return true
                })
            },
        },
        methods: {
            displayName(courier) {
                const firstName = courier.user?.firstName ?? ''
                const lastName = courier.user?.lastName ?? ''
                return firstName + ' ' + lastName
            },
            dataUpdate() {
                this.$emit('update')
            },
        },
        mounted() {
            this.filter_couriers = [
                {
                    text: '< Все >',
                    value: 0,
                },
                {
                    text: 'Свободен',
                    value: this.CourierStatus.free,
                    icon: 'mdi-check-bold',
                },
                {
                    text: 'Обрабатывает',
                    value: this.CourierStatus.processing,
                    icon: 'mdi-timer-sand',
                },
                {
                    text: 'Доставляет',
                    value: this.CourierStatus.delivering,
                    icon: 'mdi-run-fast',
                },
            ]
        }
    }
</script>
